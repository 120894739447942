<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h1 class="text-bold-300">Importações</h1>
    </div>
  </div>
</div>

<!-- Upload Simplificado -->
<div class="row mb-2">
  <div class="col">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Upload Simplificado</h4>
      </div>
      <div class="card-content">
        <div class="card-body">
          <p>Importa dados de forma simplificada, utilizando um formato genérico.</p>
          <button
            type="button"
            class="btn btn-lg btn-primary"
            (click)="uploadSimplificado()"
          >
            <i class="fa fa-upload"></i> Upload Simplificado
          </button>

          <!-- Tabela de Importação -->
          <div class="table-responsive mt-4" *ngIf="listaSimplificado && listaSimplificado.length > 0">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Arquivo</th>
                  <th>Status</th>
                  <th>Data de Inclusão</th>
                  <th>Linhas Totais</th>
                  <th>Linhas Inseridas</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let importacao of listaSimplificado">
                  <td><a [href]="importacao.url" target="_blank">Download</a></td>
                  <td>{{ traduzirStatus(importacao.status) }}</td>
                  <td>{{ formatarData(importacao.data_inclusao) }}</td>
                  <td>{{ importacao.total_rows }}</td>
                  <td>{{ importacao.inserted_rows }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Fim da Tabela de Importação -->

        </div>
      </div>
    </div>
  </div>
</div>

<!-- Upload Servidores -->
<div class="row mb-2">
  <div class="col">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Upload Servidores</h4>
      </div>
      <div class="card-content">
        <div class="card-body">
          <p>Importa os servidores e seus dados utilizando a base modelo da Secretaria da Previdência.</p>
          <button
            type="button"
            class="btn btn-lg btn-primary"
            (click)="selecionaArquivo(1)"
          >
            <i class="fa fa-upload"></i> Upload Servidores
          </button>

          <!-- Tabela de Importação -->
          <div class="table-responsive mt-4" *ngIf="listaUploadServidores && listaUploadServidores.length > 0">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Arquivo</th>
                  <th>Status</th>
                  <th>Data de Inclusão</th>
                  <th>Linhas Totais</th>
                  <th>Linhas Inseridas</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let importacao of listaUploadServidores">
                  <td><a [href]="importacao.url" target="_blank">Download</a></td>
                  <td>{{ traduzirStatus(importacao.status) }}</td>
                  <td>{{ formatarData(importacao.data_inclusao) }}</td>
                  <td>{{ importacao.total_rows }}</td>
                  <td>{{ importacao.inserted_rows }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Fim da Tabela de Importação -->

        </div>
      </div>
    </div>
  </div>
</div>

<!-- Upload Aposentados -->
<div class="row mb-2">
  <div class="col">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Upload Aposentados</h4>
      </div>
      <div class="card-content">
        <div class="card-body">
          <p>Importa os aposentados e seus dados utilizando a base modelo da Secretaria da Previdência.</p>
          <button
            type="button"
            class="btn btn-lg btn-primary"
            (click)="selecionaArquivo(2)"
          >
            <i class="fa fa-upload"></i> Upload Aposentados
          </button>

          <!-- Tabela de Importação -->
          <div class="table-responsive mt-4" *ngIf="listaUploadAposentados && listaUploadAposentados.length > 0">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Arquivo</th>
                  <th>Status</th>
                  <th>Data de Inclusão</th>
                  <th>Linhas Totais</th>
                  <th>Linhas Inseridas</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let importacao of listaUploadAposentados">
                  <td><a [href]="importacao.url" target="_blank">Download</a></td>
                  <td>{{ traduzirStatus(importacao.status) }}</td>
                  <td>{{ formatarData(importacao.data_inclusao) }}</td>
                  <td>{{ importacao.total_rows }}</td>
                  <td>{{ importacao.inserted_rows }}</td>                
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Fim da Tabela de Importação -->

        </div>
      </div>
    </div>
  </div>
</div>

<!-- Upload Pensionistas -->
<div class="row mb-2">
  <div class="col">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Upload Pensionistas</h4>
      </div>
      <div class="card-content">
        <div class="card-body">
          <p>Importa os pensionistas e seus dados utilizando a base modelo da Secretaria da Previdência.</p>
          <button
            type="button"
            class="btn btn-lg btn-primary"
            (click)="selecionaArquivo(3)"
          >
            <i class="fa fa-upload"></i> Upload Pensionistas
          </button>

          <!-- Tabela de Importação -->
          <div class="table-responsive mt-4" *ngIf="listaUploadPensionistas && listaUploadPensionistas.length > 0">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Arquivo</th>
                  <th>Status</th>
                  <th>Data de Inclusão</th>
                  <th>Linhas Totais</th>
                  <th>Linhas Inseridas</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let importacao of listaUploadPensionistas">
                  <td><a [href]="importacao.url" target="_blank">Download</a></td>
                  <td>{{ traduzirStatus(importacao.status) }}</td>
                  <td>{{ formatarData(importacao.data_inclusao) }}</td>
                  <td>{{ importacao.total_rows }}</td>
                  <td>{{ importacao.inserted_rows }}</td>                 
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Fim da Tabela de Importação -->

        </div>
      </div>
    </div>
  </div>
</div>

<!-- Upload Idade Aposentadoria -->
<div class="row mb-2">
  <div class="col">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Upload Idade Aposentadoria</h4>
      </div>
      <div class="card-content">
        <div class="card-body">
          <p>Importa a idade de aposentadoria projetada dos servidores ativos.</p>
          <button
            type="button"
            class="btn btn-lg btn-primary"
            (click)="uploadIdadeAposentadoria()"
          >
            <i class="fa fa-upload"></i> Upload Idade Aposentadoria
          </button>

          <!-- Tabela de Importação -->
          <div class="table-responsive mt-4" *ngIf="listaUploadIdadeAposentadoria && listaUploadIdadeAposentadoria.length > 0">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Arquivo</th>
                  <th>Status</th>
                  <th>Data de Inclusão</th>
                  <th>Linhas Totais</th>
                  <th>Linhas Inseridas</th>                 
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let importacao of listaUploadIdadeAposentadoria">
                  <td><a [href]="importacao.url" target="_blank">Download</a></td>
                  <td>{{ traduzirStatus(importacao.status) }}</td>
                  <td>{{ formatarData(importacao.data_inclusao) }}</td>
                  <td>{{ importacao.total_rows }}</td>
               
                  <td>
                    <button *ngIf="importacao.invalid_cpfs && importacao.invalid_cpfs.length > 0"
                      (click)="downloadInvalidCpfs(importacao.invalid_cpfs)"
                      class="btn btn-sm btn-secondary">
                      Download
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Fim da Tabela de Importação -->

        </div>
      </div>
    </div>
  </div>
</div>

<!-- Importação Mensal -->
<div class="row mb-2">
  <div class="col">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Importação Mensal</h4>
      </div>
      <div class="card-content">
        <div class="card-body">
          <p>Importa os salários mensais de contribuição dos servidores.</p>
          <button
            type="button"
            class="btn btn-lg btn-primary mb-2 mb-md-0"
            (click)="novoUploadMovimentacao()"
          >
            <i class="fa fa-plus"></i> Adicionar Importação
          </button>

          <!-- Tabela de Importação -->
          <div class="table-responsive mt-4" *ngIf="listaImportacaoMensal && listaImportacaoMensal.length > 0">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Arquivo</th>
                  <th>Status</th>
                  <th>Data de Inclusão</th>
                  <th>Linhas Totais</th>
                  <th>Linhas Inseridas</th>
                  <th>Competência</th>
                  <th>CPFs Inválidos</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let importacao of listaImportacaoMensal">
                  <td><a [href]="importacao.url" target="_blank">Download</a></td>
                  <td>{{ traduzirStatus(importacao.status) }}</td>
                  <td>{{ formatarData(importacao.data_inclusao) }}</td>
                  <td>{{ importacao.total_rows }}</td>
                  <td>{{ importacao.inserted_rows }}</td>
                  <td>{{ formatarCompetencia(importacao.competencia) }}</td>
                  <td>
                    <button *ngIf="importacao.invalid_cpfs && importacao.invalid_cpfs.length > 0"
                      (click)="downloadInvalidCpfs(importacao.invalid_cpfs)"
                      class="btn btn-sm btn-secondary">
                      Download
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Fim da Tabela de Importação -->

        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>

<div class="row mb-2 mt-2">
    <div class="col">
      <div class="card-content">
        <div class="card-body p-0">
            <div class="row">
              <div class="col">
                <p style="font-size: 1rem; font-weight: 500">
                  Nome Completo:
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group mb-2">
                    <input
                      type="text"
                      placeholder="Nome Completo"
                      id="nomeCompleto"
                      class="form-control"
                      name="nomeCompleto"
                      [(ngModel)]="servidor.nome"
                      #inputName="ngModel"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <br>

            <div class="card-body p-0">
                <div class="row">
                    <div class="col">
                        <p style="font-size: 1rem; font-weight: 500">
                            CPF:
                        </p>
                        <div class="form-group mb-1">
                            <input
                                type="text"
                                placeholder="CPF"
                                id="cpf"
                                class="form-control"
                                name="cpf"
                                [(ngModel)]="servidor.cpf"
                                #inputCpf="ngModel"
                                required
                            />
                        </div>
                    </div>
                    <div class="col">
                        <p style="font-size: 1rem; font-weight: 500">
                            Data de Nascimento:
                        </p>
                        <div class="form-group mb-1">
                            <input
                                type="text"
                                placeholder="Data de Nascimento"
                                id="dataNascimento"
                                class="form-control"
                                name="dataNascimento"
                                [(ngModel)]="servidor.data_nascimento"
                                #inputDataNascimento="ngModel"
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>

            <br>
            <div class="card-body p-0">
                <div class="row">
                    <div class="col">
                        <p style="font-size: 1rem; font-weight: 500">
                            Sexo:
                        </p>
                        <div class="form-group mb-1">
                            <input
                                type="text"
                                placeholder="Sexo"
                                id="sexo"
                                class="form-control"
                                name="sexo"
                                [(ngModel)]="servidor.sexo"
                                #inputCpf="ngModel"
                                required
                            />
                        </div>
                    </div>
                    <div class="col">
                        <p style="font-size: 1rem; font-weight: 500">
                            Matrícula:
                        </p>
                        <div class="form-group mb-1">
                            <input
                                type="text"
                                placeholder="Matrícula"
                                id="matricula"
                                class="form-control"
                                name="matricula"
                                [(ngModel)]="servidor.matricula"
                                #inputDataNascimento="ngModel"
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>

            <br>

            <div class="card-body p-0">
              <div class="row">
                  <div class="col">
                      <p style="font-size: 1rem; font-weight: 500">
                          Data Entrada Serviço Público:
                      </p>
                      <div class="form-group mb-1">
                          <input
                              type="text"
                              placeholder="Data Admissão"
                              id="dataAdmissao"
                              class="form-control"
                              name="dataAdmissao"
                              [(ngModel)]="servidor.data_entr_serv_publico"
                              #inputCpf="ngModel"
                              required
                          />
                      </div>
                  </div>
                  <div class="col">
                      <p style="font-size: 1rem; font-weight: 500">
                          Data Carreira:
                      </p>
                      <div class="form-group mb-1">
                          <input
                              type="text"
                              placeholder="Data Entrada Último Cargo"
                              id="matricula"
                              class="form-control"
                              name="matricula"
                              [(ngModel)]="servidor.data_carreira"
                              #inputDataNascimento="ngModel"
                              required
                          />
                      </div>
                  </div>
              </div>
          </div>

          <br>


            <div class="card-body p-0">
                <div class="row">
                    <div class="col">
                        <p style="font-size: 1rem; font-weight: 500">
                            Data Admissão:
                        </p>
                        <div class="form-group mb-1">
                            <input
                                type="text"
                                placeholder="Data Admissão"
                                id="dataAdmissao"
                                class="form-control"
                                name="dataAdmissao"
                                [(ngModel)]="servidor.data_admissao"
                                #inputCpf="ngModel"
                                required
                            />
                        </div>
                    </div>
                    <div class="col">
                        <p style="font-size: 1rem; font-weight: 500">
                            Data Entrada Último Cargo:
                        </p>
                        <div class="form-group mb-1">
                            <input
                                type="text"
                                placeholder="Data Entrada Último Cargo"
                                id="matricula"
                                class="form-control"
                                name="matricula"
                                [(ngModel)]="servidor.dataAdmissaoUltimoCargo"
                                #inputDataNascimento="ngModel"
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>

            <br>


            <div class="card-body p-0">
                <div class="row">
                    <div class="col">
                        <p style="font-size: 1rem; font-weight: 500">
                        Último Cargo:
                        </p>
                        <div class="form-group mb-1">
                            <input
                                type="text"
                                placeholder=" Último Cargo"
                                id="ultimoCargo"
                                class="form-control"
                                name="ultimoCargo"
                                [(ngModel)]="servidor.ultimoCargo"
                                #inputCpf="ngModel"
                                required
                            />
                        </div>
                    </div>
                    <div class="col">
                        <p style="font-size: 1rem; font-weight: 500">
                            Última remuneração / Média Mês a Mês:
                        </p>
                        <div class="form-group mb-1">
                            <input
                                type="text"
                                placeholder="Última remuneração / Média Mês a Mês"
                                id="ultimaRemuneracao"
                                class="form-control"
                                name="ultimaRemuneracao"
                                [(ngModel)]="servidor.ultimoSalario"
                                #inputDataNascimento="ngModel"
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>

            <br>
            <div class="card-body p-0">
                <div class="row">
                    <div class="col">
                        <p style="font-size: 1rem; font-weight: 500">
                       Remuneração Média desde Julho 1994:
                       <a style="font-size: 0.8rem; font-weight: 500" (click)="openModalMovimentos(movimentosServidor)">Ver Valores</a>
                        </p>
                        <div class="form-group mb-1">
                            <input
                                type="text"
                                placeholder=" Remuneração Média desde Julho 1994"
                                id="remuneracaoMedia94"
                                class="form-control"
                                name="remuneracaoMedia94"
                                [(ngModel)]="servidor.mediaSalarial"
                                #inputCpf="ngModel"
                                required
                            />
                        </div>
                    </div>
                    <div class="col">
                        <p style="font-size: 1rem; font-weight: 500">
                          Data Base:
                        </p>
                        <div class="form-group mb-1">
                            <input
                                type="text"
                                placeholder="Data Base"
                                id="dataBase"
                                class="form-control"
                                name="dataBase"
                                [(ngModel)]="dataBase"
                                #inputDataNascimento="ngModel"
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>

            <br>
            <div class="card-body p-0">
                <div class="row">
                    <div class="col">
                        <p style="font-size: 1rem; font-weight: 500">
                            Tempo Averbado:
                            <a style="font-size: 0.8rem; font-weight: 500" (click)="openModal(calculadoraTempoAberbado)">Calcular Dias</a>

                        </p>
                        <div class="form-group mb-1">
                            <input
                                type="text"
                                placeholder="Tempo Averbado"
                                id="tempoAverbado"
                                class="form-control"
                                name="tempoAverbado"
                                [(ngModel)]="tempoAverbado"
                                #inputCpf="ngModel"
                                required
                            />
                        </div>
                    </div>
                    <div class="col">
                        <p style="font-size: 1rem; font-weight: 500">
                            Tipo de Cálculo:
                          </p>
                        <div class="form-group">
                          <div class="controls">
                            <ng-select
                              [items]="tipoCalculo"
                              name="tipocalculo"
                              bindLabel="name"
                              placeholder="Tipo de Cálculo"
                              bindValue="id"
                              id="tipoCalculo"
                              [(ngModel)]="tipoCalculoSelecionado"
                              (ngModelChange)="calcular()"
                            ></ng-select>
                          </div>
                        </div>
                        </div>
                </div>
                <div class="form-row">
                  <div class="col-12 col-md-4">
                    <div class="form-group">
                      <div class="controls">
                        <label for="sexo">Regime Previdenciário</label>
                        <ng-select [appendTo]="'body'" [items]="regimesPrevidenciarios" name="regimePrevidenciario"
                          bindLabel="name" placeholder="Regime Previdenciário" bindValue="name" id="regimePrevidenciario"
                          [(ngModel)]="servidor.regimePrevidenciario"></ng-select>
                      </div>
                    </div>
                  </div>
                </div>
                                    <div class="col">
                        <p style="font-size: 1rem; font-weight: 500">
                            &nbsp; 
                        </p>
                        <div class="form-group mb-1">
                            <button class="btn btn-info w-100" (click)="postParametrosSimulcao(simulacaoResultados)" [disabled]="!checkParamsWithDetailedErrors()">Gerar Simulação</button>
                        </div>
                        
                    </div>                    
            </div>
            
            
            

    </div>

    <ng-template #calculadoraTempoAberbado let-modal>
        <ngx-spinner></ngx-spinner>
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Calcula Tempo Averbado Servidor</h4>
          </div>
          <div class="card-content">
            <div class="card-body">
              <div class="row mb-2 mt-2">
                <div class="col">
                  <div class="card-content">
                    <div class="card-body p-0">
                      <div class="row">
                        <div class="col-12">
                            <div class="form-group mb-2">
                              <label for="name">Data Inicial</label>
                              <input type="date" id="data_inicio" class="form-control" name="data_inicio" [(ngModel)]="dataInicioAberbado"
                              (change)="calculaDiasTempoAverbado()" required />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group mb-2">
                              <label for="name">Data Final</label>
                              <input type="date" id="data_fim" class="form-control" name="data_fim"
                                 required [(ngModel)]="dataFimAberbado" (change)="calculaDiasTempoAverbado()" />
                            </div>
                          </div>  
                          
                          <div class="col-12">
                            <div class="form-group mb-2">
                              <label for="name">Tempo em Dias</label>
                              <input type="number" id="tempoAverbado" class="form-control" name="tempoAverbado" [(ngModel)]="tempoAverbado"
                              #inputName="ngModel" required />
                            </div>
                          </div>     
                          
                          <div class="col-12">
                            <div class="form-group mb-2">
                             <button class="btn btn-info btn-block" (click)="modal.close('Close click')">Fechar</button>
                            </div>
                          </div> 
                    
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
        </div>
      </ng-template>
  
    <ng-template #movimentosServidor let-modal>
      <ngx-spinner></ngx-spinner>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Movimentos Servidor desde 1994</h4>
        </div>
        <div class="card-content">
          <div  class="card-body">
              <div class="row mb-2 mt-2" *ngIf="movimentos.length > 0">
                  <div class="col">
                    <div class="card-content">
                      <div class="card-body p-0">
                        <div class="table text-center m-0">
                          <table class="table-striped" style="width: 100%">
                            <thead>
                              <tr>
                                <th>Competência</th>
                                <th>Valor</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let s of movimentos
                                    | slice
                                      : (page - 1) * pageSize
                                      : (page - 1) * pageSize + pageSize
                                "
                              >
                                <td>{{ s.competencia }}</td>
                                <td>R$ {{ s.valor }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="form-row" style="margin: 5px">
                        <div
                          class="d-flex justify-content-center"
                          style="margin-left: auto; margin-right: auto"
                        >
                          <ngb-pagination
                            style="margin-top: 10px"
                            *ngIf="movimentos.length > 0"
                            [(page)]="page"
                            [pageSize]="pageSize"
                            [maxSize]="2"
                            [rotate]="true"
                            [boundaryLinks]="true"
                            size="sm"
                            [collectionSize]="movimentos.length"
                          >
                          </ngb-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-2">
                    <button class="btn btn-info btn-block" (click)="modal.close('Close click')">Fechar</button>
                  </div>
                </div> 
          </div>

      </div>
      </div>
    </ng-template>
  
    <ng-template #simulacaoResultados let-modal>
      <ngx-spinner></ngx-spinner>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Possibilidade de Aposentadoria</h4>
        </div>
        <div class="card-content">
          <div  class="card-body">
              <div class="row mb-2 mt-2" *ngIf="simulacaoResult && simulacaoResult.length > 0">
                  <div class="col">
                    <div class="card-content">
                      <div class="card-body p-0">
                        <div class="table text-center m-0">
                          <table class="table-striped" style="width: 100%">
                            <thead>
                              <tr>
                                <th>Simulação</th>
                                <th>Aposentadoria</th>
                                <th>Estimativa Idade</th>
                                <th>Artigo</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let s of simulacaoResult
                                    | slice
                                      : (page - 1) * pageSize
                                      : (page - 1) * pageSize + pageSize
                                "
                              >
                                <td>{{ s.regra }}</td>
                                <td>faltam {{ s.aposentadoria }} anos</td>
                                <td>{{ s.estimativa }} anos</td>
                                <td>{{ s.artigo }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="form-row" style="margin: 5px">
                        <div
                          class="d-flex justify-content-center"
                          style="margin-left: auto; margin-right: auto"
                        >
                          <ngb-pagination
                            style="margin-top: 10px"
                            *ngIf="simulacaoResult.length > 0"
                            [(page)]="page"
                            [pageSize]="pageSize"
                            [maxSize]="2"
                            [rotate]="true"
                            [boundaryLinks]="true"
                            size="sm"
                            [collectionSize]="simulacaoResult.length"
                          >
                          </ngb-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-2">
                    <button class="btn btn-info btn-block" (click)="modal.close('Close click')">Fechar</button>
                    <button class="btn btn-info btn-block" (click)="generatePdfReport()">Gerar PDF</button>
                  </div>
                </div> 
          </div>

      </div>
      </div>
    </ng-template>

  <ngx-spinner></ngx-spinner>
<div style="background-color: #e6f7f6; padding: 1em" *ngIf="hasSimulatorAccess==false">
  <h4 class="my-4" style="color: #0e6161; font-size: 1.2em">
    Acesso restrito: Requisite suas credenciais de acesso.
  </h4>
  <p style="font-size: 0.9em">
    Este serviço requer autenticação. Por favor, entre em contato com o administrador para acessar esta ferramenta.
  </p>
</div>
<div class="row text-left" *ngIf="hasSimulatorAccess==true">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between">
      <h2 class="text-bold-300" style="padding-bottom: 1.5rem">
        Simulador de Aposentadoria
      </h2>
    </div>
  </div>
</div>
<div class="row" *ngIf="hasSimulatorAccess==true">
  <div class="col">
    <ng-select  [items]="filters" name="filtros"  placeholder="Filtros" id="filtros" (change)="selectFilter($event)" [(ngModel)]="selectedFilter"></ng-select>
  </div>
  <div class="col" *ngIf="selectedFilter && selectedFilter=='cpf'">
    <input type="text" id="cpf"  class="form-control" style="background-color: white; height: 100%" placeholder="Cpf" name="cpf" [(ngModel)]="filterCpf" (keyup)="filter()" >
  </div>
  <div class="col" *ngIf="selectedFilter && selectedFilter=='nome'">
    <input  type="text" id="nome"  class="form-control" style="background-color: white; height: 100%;" placeholder="Nome" name="matricula" [(ngModel)]="filterName" (keyup)="filter()" >
  </div>
  <div class="col" *ngIf="selectedFilter && selectedFilter=='matricula'">
    <input  type="text" id="matricula"  class="form-control" style="background-color: white; height: 100%;" placeholder="Matricula" name="matricula" [(ngModel)]="filterMatricula" (keyup)="filter()" >
  </div>
  <div class="col" *ngIf="selectedFilter && selectedFilter=='data de nascimento'">
    <input type="text" id="nascimento"  class="form-control" style="background-color: white; height: 100%" mask="00/00/0000" placeholder="Data Nascimento" name="nascimento" [(ngModel)]="filterNascimento" (keyup)="filter()" >
  </div>
  <div class="col" *ngIf="selectedFilter && selectedFilter=='status'">
    <ng-select [items]="statusServidor"   name="status" bindLabel="name" bindValue="id"  placeholder="Status" id="status" (change)="filter()" [(ngModel)]="filterStatus"></ng-select>
  </div>
  <div class="col" *ngIf="selectedFilter && selectedFilter=='data de cadastro'">
    <input type="text" id="cadastro"  class="form-control" style="background-color: white; height: 100%" mask="00/00/0000" placeholder="Data Cadastro" name="cadastro" [(ngModel)]="filterCadastro" (keyup)="filter()" >
  </div>
</div>
<div class="row mb-2 mt-2" *ngIf="hasSimulatorAccess==true && servidores.length > 0">  
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0">
        <div class="table text-center m-0">
          <table class="table-striped" style="width: 100%">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Telefone</th>
                <th>Matrícula</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="
                  let s of servidoresFiltered
                    | slice
                      : (page - 1) * pageSize
                      : (page - 1) * pageSize + pageSize
                ">
                <td>{{ s.nome }}</td>
                <td>{{ s.email }}</td>
                <td>{{ s.telefone ? s.telefone : "Sem telefone" }}</td>
                <td>{{ s.matricula }}</td>
                <td>
                  <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                    <button type="button" class="btn btn-outline-info btn-sm dropdown-toggle" ngbDropdownToggle>
                      <i class="ft-more-vertical"></i>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu" role="menu">
                      <a
                        class="dropdown-item"
                        routerLink="/page/servidor" [queryParams]="{id: s.id}"
                        >Editar Servidor</a
                      >
                      <a
                        class="dropdown-item"
                        (click)="navigateTo(s, 'page/servidor/anexo')"
                        >Documentos Servidor</a
                      >
                      <a
                      class="dropdown-item"
                      [routerLink]="['/page/simulador', s]"
                      >Simulador</a
                    >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="form-row" style="margin: 5px">
        <div class="d-flex justify-content-center" style="margin-left: auto; margin-right: auto">
          <ngb-pagination style="margin-top: 10px" *ngIf="servidores.length > 0" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="2" [rotate]="true" [boundaryLinks]="true" size="sm" [collectionSize]="servidores.length">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="hasSimulatorAccess && servidores.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0 mt-2">
        <ul class="list-group">
          <li class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum Servidor cadastrado.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- <ng-template #modalServidor let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Servidor</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12 col-md-6">
              <div class="form-group mb-2">
                <label for="name">Nome do Servidor</label>
                <input type="text" id="nome" class="form-control" name="nome" [(ngModel)]="servidor.nome"
                  #inputName="ngModel" required />
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group mb-2">
                <label for="name">Data de Nascimento</label>
                <input type="date" id="data_nascimento" class="form-control" name="data_nascimento"
                  [(ngModel)]="servidor.data_nascimento" #inputName="ngModel" required />
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group mb-2">
                <label for="name">RG do Servidor</label>
                <input type="text" id="nome" class="form-control" name="rg" [(ngModel)]="servidor.rg"
                  #inputName="ngModel" required />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12 col-md-3">
              <div class="form-group mb-2">
                <label for="name">CPF</label>
                <input type="text" id="nome" class="form-control" name="cpf" [(ngModel)]="servidor.cpf"
                  #inputName="ngModel" required />
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group">
                <div class="controls">
                  <label for="sexo">Sexo</label>
                  <ng-select [items]="sexos" name="sexo" bindLabel="name" placeholder="Sexo" bindValue="id" id="sexo"
                    [(ngModel)]="servidor.sexo"></ng-select>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group mb-2">
                <label for="name">Email</label>
                <input type="text" id="nome" class="form-control" name="email" [(ngModel)]="servidor.email"
                  #inputName="ngModel" required />
              </div>
            </div>
            <div class="col-12 col-md-2">
              <div class="form-group mb-2">
                <label for="name">Telefone</label>
                <input type="text" id="nome" class="form-control" name="telefone" [(ngModel)]="servidor.telefone"
                  #inputName="ngModel" required />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12 col-md-3">
              <div class="form-group mb-2">
                <label for="name">Matrícula</label>
                <input type="text" id="matricula" class="form-control" name="matricula" [(ngModel)]="servidor.matricula"
                  #inputName="ngModel" required />
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group mb-2">
                <label for="name">PIS</label>
                <input type="text" id="pis" class="form-control" name="pis" [(ngModel)]="servidor.pis"
                  #inputName="ngModel" required />
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group mb-2">
                <label for="name">Entrada no Serviço Público</label>
                <input type="date" id="data_entr_serv_publico" class="form-control" name="data_entr_serv_publico"
                  [(ngModel)]="servidor.data_entr_serv_publico" #inputName="ngModel" required />
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group mb-2">
                <label for="name">Data Carreira</label>
                <input type="date" id="data_carreira" class="form-control" name="data_carreira"
                [(ngModel)]="servidor.data_carreira" #inputName="ngModel" required />
              </div>
            </div>
            
          </div>
          <div class="form-row">
            <div class="col-12">
              <div class="form-group mb-2">
                <label for="name">Endereço</label>
                <input type="text" id="endereco" class="form-control" name="endereco" [(ngModel)]="servidor.endereco"
                  #inputName="ngModel" required />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12 col-md-3">
              <div class="form-group mb-2">
                <label for="name">Data de Admissão</label>
                <input type="date" id="data_admissao" class="form-control" name="data_admissao"
                  [(ngModel)]="servidor.data_admissao" #inputName="ngModel" required />
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group mb-2">
                <label for="name">Último Cargo</label>
                <input type="text" id="ultimoCargo" class="form-control" name="ultimoCargo"
                  [(ngModel)]="servidor.ultimoCargo" #inputName="ngModel" required />
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group mb-2">
                <label for="name">Data Adm. Último Cargo</label>
                <input type="date" id="dataAdmissaoUltimoCargo" class="form-control" name="dataAdmissaoUltimoCargo"
                  [(ngModel)]="servidor.dataAdmissaoUltimoCargo" #inputName="ngModel" required />
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group mb-2">
                <label for="name">Último Salário</label>
                <input type="text" id="ultimoSalario" class="form-control" name="ultimoSalario"
                  [(ngModel)]="servidor.ultimoSalario" #inputName="ngModel" required />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12 col-md-4">
              <div class="form-group">
                <div class="controls">
                  <label for="sexo">Regime Previdenciário</label>
                  <ng-select [appendTo]="'body'" [items]="regimesPrevidenciarios" name="regimePrevidenciario"
                    bindLabel="name" placeholder="Regime Previdenciário" bindValue="name" id="regimePrevidenciario"
                    [(ngModel)]="servidor.regimePrevidenciario"></ng-select>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <button type="button" class="btn btn-lg btn-info mb-2 mb-md-0 float-right" [disabled]="false"
                (click)="salvarServidor(modal)">
                {{
                this.servidor.id ? "Editar Servidor" : "Cadastrar Servidor"
                }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #arquivosServidor let-modal>
  <ngx-spinner></ngx-spinner>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Documentos Servidor</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <div class="row mb-2 mt-2">
          <div class="col">
            <div class="card-content">
              <div class="card-body p-0">
                <div class="row">
                  <div class="col-12 col-lg-6" *ngFor="let doc of documentos; let i = index">
                    <div class="row">
                      <div class="col">
                        <p style="font-size: 1rem; font-weight: 500">
                          <i class="ft-file"></i>
                          {{ doc.descricao }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6" *ngIf="doc.arquivos.length > 0">
                        <ul *ngFor="let arquivo of doc.arquivos">
                          <a class="info" href="{{ arquivo.arquivo }}" target="_blank">
                            <i class="ft-file"></i>
                            Visualizar Arquivo
                          </a>
                        </ul>
                      </div>
                      <div class="col-6" *ngIf="doc.arquivos.length == 0">
                        <i class="ft-file"></i>
                        Sem Arquivos
                      </div>

                      <div class="col-6">
                        <label class="btn bg-light-info" for="select-files-{{i}}">Carregar Arquivo</label>
                        <input type="file" accept="*" (change)="uploadDocument($event, doc, i, doc.unico)"
                          id="select-files-{{i}}" hidden />
                      </div>

                    </div>
                  
                  <hr />
              
                    <div class="row">
                      <div class="col" style="display: flex; justify-content: flex-end; flex-direction: column;">
                        <div class="row">
                          <div *ngIf="doc.arquivos.length > 0">
                            <div class="col-6" *ngFor="let arquivo of doc.arquivos">
                              <a href="{{ arquivo.arquivo }}" target="_blank">
                                <i class="ft-file"></i>
                                Visualizar Arquivo</a>
                            </div>
                          </div>
                          <div class="col-6" *ngIf="doc.arquivos.length == 0">
                            <i class="ft-file"></i>
                            Sem Arquivos
                          </div>
                          <div class="col-6">
                            <label class="btn bg-light-info" for="select-files-{{i}}">Carregar Arquivo</label>
                            <input type="file" accept="*" (change)="uploadDocument($event, doc, i, doc.unico)"
                              id="select-files-{{i}}" hidden />
                          </div>
                        </div>
                      </div>
                    </div> 

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</ng-template> -->
<ngx-spinner></ngx-spinner>
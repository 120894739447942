import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Api from 'app/helpers/api';
import App from 'app/helpers/app';
import ApiSimulador from 'app/helpers/apiSimulador';
import Me from 'app/helpers/me';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/internal/operators/take';
import * as pdfMake from "pdfmake/build/pdfmake"
import * as pdfFonts from "pdfmake/build/vfs_fonts"
//pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-simulador',
  templateUrl: './simulador.component.html',
  styleUrls: ['./simulador.component.scss']

})
export class SimuladorComponent implements OnInit {
  apiCall = this.api.new().silent();
  apiCallSimulador = this.apiSimulador.new().silent();

  page = 1
  pageSize = 10
  dataInicioAberbado = null;
  dataFimAberbado = null;
  tempoAverbado = 0;
  remuneracaoMedia94 = null;
  dataBase = null;
  servidor = {
    id: null,
    nome: null,
    cidade: null,
    rg: null,
    cpf: null,
    endereco: null,
    email: null,
    telefone: null,
    pis: null,
    matricula: null,
    data_nascimento: null,
    data_carreira: null,
    data_entr_serv_publico: null,
    sexo: null,
    data_admissao: null,
    ultimoCargo: null,
    dataAdmissaoUltimoCargo: null,
    regimePrevidenciario: null,
    ultimoSalario: null,
    id_cidade: null,
    mediaSalarial: null,
    movimentos: []
  };
  user = null

  movimentos = [];
  tipoCalculo = [
    {
      id: 1,
      name: 'Média Salarial',
    }, {
      id: 2,
      name: 'Ultima Remuneração',
    }
  ];
  regimesPrevidenciarios = [
    {
      name: 'Aposentadoria Voluntária',
    }
    , {
      name: 'Aposentadoria Especial para Professor',
    }
    , {
      name: 'Aposentadoria Especial - Periculosidade',
    }
  ]
  tipoCalculoSelecionado: any;
  result = {
    typeId: null,
    value: "XXXXXXX"
  };
  parametrosSimulacao = {
    data_nascimento: null,
    data_admissao: null,
    data_servico_publico: null,
    data_carreira: null,
    data_cargo: null,
    sexo: null,
    cargo: null,
    idCidade: null,
    idServidor: null,
    regime_previdenciario: null
  }
  simulacaoResult = [];
  movimentosServidor = [];
  submitErrors = [];

  constructor(
    private api: Api,
    private apiSimulador: ApiSimulador,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private app: App,
    private me: Me,
    private route: ActivatedRoute,
    private router: Router,
  ) { }



  async ngOnInit() {
    const self = this;
    this.route.paramMap.subscribe(params => {
      this.servidor.id = params.get('id');
      this.servidor.cidade = params.get('id_cidade');
      this.servidor.nome = params.get('nome');
      this.servidor.rg = params.get('rg');
      this.servidor.cpf = params.get('cpf');
      this.servidor.data_nascimento = this.preparaData(params.get('data_nascimento'));
      this.servidor.data_carreira = this.preparaData(params.get('data_carreira'));
      this.servidor.data_entr_serv_publico = this.preparaData(params.get('data_entr_serv_publico'));
      this.servidor.sexo = this.preparaSexo(params.get('sexo'));
      this.servidor.matricula = params.get('matricula');
      this.servidor.dataAdmissaoUltimoCargo = this.preparaData(params.get('data_ingresso_serv'));
      this.servidor.ultimoCargo = null;
      this.servidor.mediaSalarial = params.get('mediaSalarial');
      this.servidor.ultimoSalario = params.get('ultimoSalario');
      this.servidor.data_admissao = this.preparaData(params.get('data_ingresso_ente'))
      this.movimentos = [{ competencia: '10/95', valor: 1210.20 }, { competencia: '11/95', valor: 1239.50 }, { competencia: '12/95', valor: 1790.42 }, { competencia: '12/95', valor: 1390.00 }, { competencia: '12/95', valor: 1350.20 }, { competencia: '12/95', valor: 1333.97 }, { competencia: '12/95', valor: 1390.90 }, { competencia: '12/95', valor: 1390.10 }, { competencia: '12/95', valor: 1387.40 }, { competencia: '12/95', valor: 1390.00 }, { competencia: '12/95', valor: 1390.00 }, { competencia: '12/95', valor: 1310.34 }, { competencia: '12/95', valor: 1345.12 }];
      this.servidor.regimePrevidenciario = params.get('regimePrevidenciario');
      this.calcular();

      const dadosUsuario = self.me.currentUserValue;
      self.user = JSON.parse(JSON.stringify(dadosUsuario));

      this.parametrosSimulacao.cargo = this.servidor.ultimoCargo;
      this.parametrosSimulacao.data_nascimento = this.servidor.data_nascimento;
      this.parametrosSimulacao.data_admissao = this.servidor.data_admissao;
      this.parametrosSimulacao.data_servico_publico = this.servidor.data_entr_serv_publico;
      this.parametrosSimulacao.data_carreira = this.servidor.data_carreira;
      this.parametrosSimulacao.data_cargo = this.servidor.dataAdmissaoUltimoCargo;
      this.parametrosSimulacao.sexo = params.get('sexo');
      this.parametrosSimulacao.idCidade = self.user.id_cidade;
      this.parametrosSimulacao.idServidor = this.servidor.id;
      this.parametrosSimulacao.regime_previdenciario = this.servidor.regimePrevidenciario;         
    });

    const movimentacoesmensais = await self.apiCall
    .get(
      "servidor/movimentos/movimentacoesmensais/recuperar?id_servidor=" +
      this.servidor.id
    )
    .pipe(take(1))
    .toPromise();
  if (movimentacoesmensais)
    console.log("movimentacoesmensais", movimentacoesmensais);
    self.movimentosServidor = JSON.parse(JSON.stringify(movimentacoesmensais));
    self.servidor.mediaSalarial = this.calcularMediaValor(self.movimentosServidor);
    console.log("media salarial", self.servidor.mediaSalarial);
  }

  calcularMediaValor(data) {
    // Soma todos os valores do campo "valor"
    const soma = data.reduce((total, item) => total + item.valor, 0);
    
    // Calcula a média dividindo pela quantidade de itens
    const media = soma / data.length;

    var resultadoArredondado = media.toFixed(2);
    return "R$ " + resultadoArredondado.toString();
}

  openModal(content) {
    this.dataInicioAberbado = null
    this.dataFimAberbado = null
    this.modalService.open(content, { size: 'md', centered: true })
  }

  openModalMovimentos(content) {
    const self = this
    self.modalService.open(content, { size: 'lg', centered: true })
  }

  calcular() {
    this.remuneracaoMedia94 = this.calcularMedia();
  }
  calcularMedia(): string {
    var count = this.movimentos.length;
    const somaValores = this.movimentos.reduce((acumulador, movimento) => acumulador + movimento.valor, 0);
    var result = somaValores / count;
    var resultadoArredondado = result.toFixed(2);
    return "R$ " + resultadoArredondado.toString();
  }
  private preparaData(data) {
    if (data) {
      return moment(data).format('DD/MM/YYYY')
    } else {
      data = ''
    }
    return data
  }
  private preparaSexo(sexo) {
    if (sexo == null) {
      return '';
    }
    if (sexo == 'M') {
      return 'Masculino';
    }
    return 'Feminino';
  }

  calculaDiasTempoAverbado() {
    const self = this

    if (self.dataInicioAberbado && self.dataFimAberbado) {
      let diffDias = moment(self.dataFimAberbado).diff(moment(self.dataInicioAberbado), 'days')

      if (diffDias < 0) {
        return false
      } {
        self.tempoAverbado = diffDias
      }
    } else {
      return false
    }

  }


  async postParametrosSimulcao(content) {
    const self = this

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    })

    let isValid = this.checkParamsWithDetailedErrors()

    if(!isValid){
      self.spinner.hide()
      self.app.alert('Por favor, preencha todos os campos corretamente.', 'warning')
      return
    }

    //        data_admissao: moment(this.parametrosSimulacao.data_admissao, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    //         data_carreira: moment(this.parametrosSimulacao.data_carreira, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    //         data_servico_publico: moment(this.parametrosSimulacao.data_servico_publico, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    //         regime_previdenciario: 'Aposentadoria Especial para Professor'this.parametrosSimulacao.regime_previdenciario





    let parms = {      
      data_nascimento: moment(this.servidor.data_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      data_admissao: moment(this.servidor.data_admissao, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      data_servico_publico: moment(this.servidor.data_entr_serv_publico, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      data_carreira: moment(this.servidor.data_carreira, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      data_cargo: moment(this.servidor.dataAdmissaoUltimoCargo, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      sexo: this.servidor.sexo == 'Masculino' ? 'M' : 'F',
      cargo: this.servidor.ultimoCargo,
      idCidade: this.servidor.cidade,
      idServidor: this.parametrosSimulacao.idServidor,
      tempo_averbado: this.tempoAverbado > 0 ? this.tempoAverbado : 0,
      regime_previdenciario: this.servidor.regimePrevidenciario
  };

  console.log("parms", parms)


    var data = await this.apiCallSimulador.post(`simulador`, parms).pipe(take(1)).toPromise();

    var response = data;
    self.simulacaoResult = response.return;

    self.spinner.hide()

    if(self.simulacaoResult.length > 0){
      self.modalService.open(content, { size: 'lg', centered: true })

    }else{
      self.app.alert('Nenhuma regra encontrada para o servidor informado', 'warning')
    }

  }

  async generatePdfReport() {

   /* const body = []

    for (let s of this.simulacaoResult) {
      const rows = []

      rows.push(s.regra)     
      rows.push(s.estimativa+" Anos")
      body.push(rows)

    }

    console.log("body", body)


    const docDefinition = {
      content: [
        {
          text: 'Simulação Aposentadoria',
          style: 'header'
        },
        {
          table: {
            widths: ['*', '*', '*', '*'], // Define as larguras das colunas
            body: [
              [{ text: 'DADOS DO SERVIDOR', colSpan: 4, style: 'tableDesc' }, {}, {}, {}],
              [{ text:'Nome', style: 'itemDesc'}, { text: this.servidor.nome, colSpan: 3 }, {}, {}],
              [{ text:'Data Nascimento', style: 'itemDesc'}, { text: this.servidor.data_nascimento, colSpan: 3 }, {}, {}],
              [{ text:'CPF', style: 'itemDesc'}, { text: this.servidor.cpf, colSpan: 3 }, {}, {}],
              [{ text:'RG Servidor', style: 'itemDesc'}, { text: this.servidor.rg, colSpan: 3 }, {}, {}],
              [{ text:'Sexo', style: 'itemDesc'}, { text: this.servidor.sexo, colSpan: 3 }, {}, {}]
              // Adicione mais linhas conforme necessário
            ]
          }
        },
        {
          // Espaçamento entre as tabelas
          text: '',
          margin: [0, 10]
        },
        {
          // Segunda tabela
          table: {
            widths: ['*', '*', '*', '*'], // Define as larguras das colunas
            body: [
              [{ text: 'DADOS FUNCIONAIS', colSpan: 4, style: 'tableDesc' }, {}, {}, {}],
              [{ text: 'Matrícula', style: 'itemDesc'}, { text: this.servidor.matricula, colSpan: 3 }, {}, {}],
              [{ text:'Cargo' , style: 'itemDesc'}, { text: this.servidor.ultimoCargo, colSpan: 3 }, {}, {}],
              [{ text:'Ingresso no Serviço Público', style: 'itemDesc'} , { text: this.servidor.data_entr_serv_publico, colSpan: 3 }, {}, {}],
              [{ text:'Data Admissão no Ente', style: 'itemDesc'}, { text: this.servidor.data_admissao, colSpan: 3 }, {}, {}],
              [{ text:'Ingresso na Carreira', style: 'itemDesc'}, { text: this.servidor.dataAdmissaoUltimoCargo, colSpan: 3 }, {}, {}],
              [{ text:'Data Admissão no Cargo', style: 'itemDesc'}, { text: this.servidor.dataAdmissaoUltimoCargo, colSpan: 3 }, {}, {}]
              // Adicione mais linhas conforme necessário
            ]
          }
        },
        {
          // Espaçamento entre as tabelas
          text: '',
          margin: [0, 10]
        },
        {
          table: {
            widths: ['50%', '50%'],
            body: [
              [{ text: 'MODALIDADES', colSpan: 2, style: 'tableDesc'}, {}],

              [{ text: 'REGRA', style: 'tableHeader' }, { text: 'ESTIMATIVA', style: 'tableHeader' }],
              //@ts-ignore
              ...body
            ]
          }
        }
      ],
      styles: {
        tableHeader: {
          fontSize: 12,
          bold: true,
          margin: [0, 0, 0, 2]
        },
        tableDesc: {
          fontSize: 13,
          bold: true,
          alignment: 'center',
        },
        itemDesc: {
          bold: true,
          alignment: 'right',
        },
        header: {
          fontSize: 15,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 10]
        }
      }
    };

    pdfMake.createPdf(docDefinition).open();*/

  }

  checkParams(){

    const {
      data_nascimento,
      data_admissao,
      data_entr_serv_publico,
      data_carreira,
      dataAdmissaoUltimoCargo,
      sexo,
      ultimoCargo,
      cidade,
      regimePrevidenciario
    } = this.servidor;

    const { idServidor } = this.parametrosSimulacao;

    const tempo_averbado = this.tempoAverbado > 0 ? this.tempoAverbado : 0;

    if (
      data_nascimento == null ||
      data_admissao == null ||
      data_entr_serv_publico == null ||
      data_carreira == null ||
      dataAdmissaoUltimoCargo == null ||
      sexo == null ||
      ultimoCargo == null ||
      cidade == null ||
      idServidor == null ||
      tempo_averbado == null ||
      regimePrevidenciario == null
    ) {
      return false;
    }

    return true;
  }

    checkParamsWithDetailedErrors(){
      const self = this;

      let submitErrors = [];

      if (self.servidor.data_nascimento == null) submitErrors.push('Data de Nascimento está faltando.');
      if (self.servidor.data_admissao == null) submitErrors.push('Data de Admissão está faltando.');
      if (self.servidor.data_entr_serv_publico == null) submitErrors.push('Data de Entrada no Serviço Público está faltando.');
      if (self.servidor.data_carreira == null) submitErrors.push('Data de Carreira está faltando.');
      if (self.servidor.dataAdmissaoUltimoCargo == null) submitErrors.push('Data de Admissão no Último Cargo está faltando.');
      if (self.servidor.sexo == null) submitErrors.push('Sexo está faltando.');
      if (self.servidor.ultimoCargo == null) submitErrors.push('Último Cargo está faltando.');
      if (self.servidor.cidade == null) submitErrors.push('Cidade está faltando.');
      if (self.parametrosSimulacao.idServidor == null) submitErrors.push('ID do Servidor está faltando.');
      if (self.tempoAverbado == null) submitErrors.push('Tempo Averbado está faltando.');
      if (self.servidor.regimePrevidenciario == null) submitErrors.push('Regime Previdenciário está faltando.');
  
      if (submitErrors.length === 0) {
        const dateFields = {
          'Data de Nascimento': self.servidor.data_nascimento,
          'Data de Admissão': self.servidor.data_admissao,
          'Data de Entrada no Serviço Público': self.servidor.data_entr_serv_publico,
          'Data de Carreira': self.servidor.data_carreira,
          'Data de Admissão no Último Cargo': self.servidor.dataAdmissaoUltimoCargo
        };
  
        const dateFormat = 'DD/MM/YYYY';
  
        for (const [fieldName, dateValue] of Object.entries(dateFields)) {
          if (!moment(dateValue, dateFormat, true).isValid()) {
            submitErrors.push(`${fieldName} não é uma data válida.`);
            console.log("erro data")
          }
        }
      }

 
      if(submitErrors.length > 0){
        return false
      }else{
        return true
      }
    }

    
  }

import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";
import ApiSimulador from 'app/helpers/apiSimulador';
import { AnexosComponent } from 'app/anexos/anexos.component';
import {
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';




@Component({
  selector: 'app-simulador-aposentadoria',
  templateUrl: './simulador-aposentadoria.component.html',
  styleUrls: ['./simulador-aposentadoria.component.scss']
})
export class SimuladorAposentadoriaComponent implements OnInit {

  servidores = []  
  selectedItems: boolean[] = [false, false, false, false];
  servidoresFiltered = [];
  filters = [
    "cpf",
    "matricula",
    "data de nascimento",
    "data de cadastro",
    "status",
    "nome",
  ];
  nomeCidade = null
  isAdmin = false
  idCidade = null
  apiCall = this.api.new().silent();
  apiCallSimulador = this.apiSimulador.new().silent();
  planilhas = [];
  cidade = null;
  page = 1;
  pageSize = 10;
  hasSimulatorAccess = false;
  selectedFilter = null;
  filterName;
  filterCpf;
  filterMatricula;
  filterNascimento;
  filterCadastro;
  filterSexo;
  filterStatus;

  constructor(
    private spinner: NgxSpinnerService,
    private me: Me,
    private app: App,
    private http: HttpClient,
    private api: Api,
    private route: ActivatedRoute,
    private apiSimulador: ApiSimulador,
    private modalService: NgbModal,
    private router: Router,

  ) { }

  selectFilter(event) { 
    this.filterName = null;
    this.filterCpf = null;
    this.filterMatricula = null;
    this.filterNascimento = null;
    this.filterCadastro = null;
    this.filterSexo = null;
    this.filterStatus = null;
    this.servidoresFiltered = this.servidores;
  }

  async ngOnInit() {
    const self = this

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    
    const dadosUsuario = self.me.currentUserValue;
    const json = JSON.parse(JSON.stringify(dadosUsuario));
    const cidade = json.user.cidade;

    if(json.user.tipo === "1"){
        self.hasSimulatorAccess = true;
    }else if(json.user.tipo === "2"){
      if(json.user.simulador){
        self.hasSimulatorAccess = true;
      }
    }

    console.log('json.user', json.user)
    self.nomeCidade = json.user.nomeCidade;
    self.isAdmin = json.user.tipo === "1";
    if (cidade) {
      self.idCidade = cidade;
    }

    console.log('cidade', cidade)

    if (json.user.tipo === "1") {
      console.log("caiu if")
      self.route.queryParams.subscribe(async (parametros) => {
        if (parametros["cidade"]) {
          let idCidade = parametros["cidade"];
          self.idCidade = idCidade;
          json.user.cidade = idCidade;
          self.me.atualizaCidade(json);
          const planilhas = await self.apiCall
            .get("servidor/arquivo/planilha?id_cidade=" + self.idCidade)
            .pipe(take(1))
            .toPromise();
          const responseCidade = await self.apiCall
            .get("enderecos/cidade?id=" + self.idCidade)
            .pipe(take(1))
            .toPromise();
          self.planilhas = JSON.parse(JSON.stringify(planilhas));
          if (responseCidade && responseCidade[0])
            self.cidade = responseCidade[0];
            self.nomeCidade = self.cidade.nome;
          self.apiCall
            .get("servidor/todos/cidade?id_cidade=" + idCidade)
            .subscribe((data) => {
              let lista = data
              ? JSON.parse(JSON.stringify(data)).reverse()
              : []
              let listafinal = []
              if(lista.length>0){
                let lista1 = lista.filter(x=>x.temDependenteMaiorDe21)
                let lista2 = lista.filter(x=>x.temTetoINSS)
                let lista3 = lista.filter(x=>x.pendencias)
                listafinal = [...lista1]
                for(let l of lista2){
                  if(!listafinal.find(x=>x==l)){
                    listafinal.push(l)
                  }
                }
                for(let l of lista3){
                  if(!listafinal.find(x=>x==l)){
                    listafinal.push(l)
                  }
                }
                for(let l of lista){
                  if(!listafinal.find(x=>x==l)){
                    listafinal.push(l)
                  }
                }
                for(let l of listafinal){
                  let duasmatriculas = listafinal.filter(x=>x.cpf&&x.cpf==l.cpf)
                  if(duasmatriculas.length>1){
                    l.duasmatriculas = true
                  }
                }
              }

            
            
              self.servidores =[...listafinal];
              self.servidoresFiltered =[...listafinal];
              console.log(listafinal, lista.length)
              self.spinner.hide();
            });
        } else {
          console.log("caiu else")
          self.apiCall
            .get("servidor/todos/cidade?id_cidade=" + cidade)
            .subscribe((data) => {
              let lista = data
              ? JSON.parse(JSON.stringify(data)).reverse()
              : []
              let listafinal = []
              if(lista.length>0){
                let lista1 = lista.filter(x=>x.temDependenteMaiorDe21)
                let lista2 = lista.filter(x=>x.temTetoINSS)
                let lista3 = lista.filter(x=>x.pendencias)
                listafinal = [...lista1]
                for(let l of lista2){
                  if(!listafinal.find(x=>x==l)){
                    listafinal.push(l)
                  }
                }
                for(let l of lista3){
                  if(!listafinal.find(x=>x==l)){
                    listafinal.push(l)
                  }
                }
                for(let l of lista){
                  if(!listafinal.find(x=>x==l)){
                    listafinal.push(l)
                  }
                }
                for(let l of listafinal){
                  let duasmatriculas = null
                  duasmatriculas = listafinal.filter(x=>x.cpf==l.cpf)
  
                  if(duasmatriculas.length>1){
                    l.duasmatriculas = true
                  }
                }
              }
              self.servidores =[...listafinal];
              self.servidoresFiltered =[...listafinal];
              console.log('ta aq', listafinal)
              self.spinner.hide();
            });
        }
      });
    } else {
      console.log("Else do else")
      const planilhas = await self.apiCall
        .get("servidor/arquivo/planilha?id_cidade=" + cidade)
        .pipe(take(1))
        .toPromise();
      self.planilhas = JSON.parse(JSON.stringify(planilhas));
      self.apiCall
        .get("servidor/todos/cidade?id_cidade=" + cidade)
        .subscribe((data) => {

          let lista = data  ? JSON.parse(JSON.stringify(data)).reverse() : []

          console.log("Lista retornada", lista)

          for(let l of lista){
            let duasmatriculas = null
            duasmatriculas = lista.filter(x=>x.cpf==l.cpf)

            if(duasmatriculas.length>1){
              l.duasmatriculas = true
            }
          }

          self.servidores = lista;
          self.servidoresFiltered = lista;


          /*self.servidores = data
            ? JSON.parse(JSON.stringify(data)).reverse()
            : [];
          self.servidoresFiltered = data
            ? JSON.parse(JSON.stringify(data)).reverse()
            : [];*/
          self.spinner.hide();
        });
    }

    console.log('servidores', self.servidores)
  }

  insereServidor(modal){

  }

  openInfoUsuariosModal() {
    const modalRef = this.modalService.open(AnexosComponent, {
      size: "lg",
    });  
  }

  navigateTo(servidor, path){
    this.router.navigate([path],  { queryParams: { id: servidor.id, idCidade: servidor.id_cidade }});
  }

  filter() {
    this.servidoresFiltered = this.servidores;
    if (this.filterName) {
      this.servidoresFiltered = this.servidoresFiltered.filter(
        (x) =>
          x.nome && x.nome.toLowerCase().includes(this.filterName.toLowerCase())
      );
    }
    if (this.filterCpf) {
      this.servidoresFiltered = this.servidoresFiltered.filter(
        (x) =>
          x.cpf && x.cpf.toLowerCase().includes(this.filterCpf.toLowerCase())
      );
    }
    if (this.filterMatricula) {
      this.servidoresFiltered = this.servidoresFiltered.filter(
        (x) =>
          (x.matricula &&
          x.matricula.toLowerCase().includes(this.filterMatricula.toLowerCase()
          ) || (x.matricula_beneficiario &&
            x.matricula_beneficiario.toLowerCase().includes(this.filterMatricula.toLowerCase()
            )

          ))
      );
    }
    if (this.filterNascimento) {
      this.servidoresFiltered = this.servidoresFiltered.filter(
        (x) =>
          x.data_nascimento &&
          moment
            .utc(x.data_nascimento)
            .format("DDMMYYYY")
            .toLowerCase()
            .includes(this.filterNascimento.toLowerCase())
      );
    }
    if (this.filterCadastro) {
      this.servidoresFiltered = this.servidoresFiltered.filter(
        (x) =>
          x.data_inclusao &&
          moment
            .utc(x.data_inclusao)
            .format("DDMMYYYY")
            .toLowerCase()
            .includes(this.filterCadastro.toLowerCase())
      );
    }
    if (this.filterSexo && this.filterSexo.id) {
      this.servidoresFiltered = this.servidoresFiltered.filter(
        (x) => x.sexo && x.sexo == this.filterSexo.id
      );
    }
    if (this.filterStatus) {
      this.servidoresFiltered = this.servidoresFiltered.filter(
        (x) => x.status && x.status == this.filterStatus
      );
    }
  }

  

}

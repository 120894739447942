import { Component, AfterViewInit, OnInit } from '@angular/core';
import Api from 'app/helpers/api';
import Me from 'app/helpers/me';
import { ChartOptions } from 'chart.js';
import { take, filter } from 'rxjs/operators';

@Component({
  selector: 'app-relatorios-grafico',
  templateUrl: './relatorios-grafico.component.html',
  styleUrls: ['./relatorios-grafico.component.scss']
})
export class RelatoriosGraficoComponent implements OnInit {
  apiCall = this.api.new().silent();
  public totalActive = 0;
  public totalInative = 0;
  public totalMaleInative = 0;
  public totalFemaleInative = 0;
  public totalMalePensionersAge = 0;
  public totalFemalePensionersAge = 0;
  public totalMaleActive = 0;
  public totalMaleRetiredInactive = 0;
  public totalFemaleRetiredInactive = 0;
  public totalFemaleActive = 0;
  public totalPensioners = 0;
  public totalMale = 0;
  public totalFemale = 0;
  public totalMaleGeneral = 0;
  public totalFemaleGeneral = 0;
  public totalMalePensioners = 0;
  public totalFemalePensioners = 0;
  public totalFemale_0_20 = 0;
  public totalFemale_20_40 = 0;
  public totalFemale_40_60 = 0;
  public totalFemale_60_80 = 0;
  public totalFemalePensioners_20_40 = 0;
  public totalFemalePensioners_40_60 = 0;
  public totalFemalePensioners_60_80 = 0;
  public totalFemalePensioners_80 = 0;
  public totalMale_0_20 = 0;
  public totalMale_20_40 = 0;
  public totalMale_40_60 = 0;
  public totalMale_60_80 = 0;
  public totalMalePensioners_20_40 = 0;
  public totalMalePensioners_40_60 = 0;
  public totalMalePensioners_60_80 = 0;
  public totalMalePensioners_80 = 0;
  public totalMagisteryFemale = 0;
  public totalMagisteryMale = 0;
  public totalOthersMale = 0;
  public totalOthersFemale = 0;
  public averageMaleSalary = 0.0;
  public averageFemaleSalary = 0.0;
  public averageGeneralSalary = 0.0;
  public averageMaleRetiredInactiveSalary = 0.0;
  public averageFemaleRetiredInactiveSalary = 0.0;
  public averageGeneralRetiredInactiveSalary = 0.0;
  public averageMalePensionersSalary = 0.0;
  public averageFemalePensionersSalary = 0.0;
  public averageGeneralPensionersSalary = 0.0;
  public chartOptionsBar: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };

  public chartOptionsPie: ChartOptions = {
    responsive: true,
  };
  public barType = 'bar';
  public pieType = 'pie';
  public insuredMassLabels = [];
  public distributionbySexActiveLabels = [];
  public distributionbySexRetiredInactiveLabels = [];
  public distributionbySexPensionersLabels = [];
  public distributionbySexLabels = [];
  public distributionbyCarrerLabels = ['Masculino', 'Feminino'];
  public averageSalaryActiveServerLabels = ['Geral', 'Masculino', 'Feminino'];
  public distributionbyAgeLabels = ['0-20', '20-40', '40-60', '60-80'];
  public distributionbyAgePensionersLabels = ['20-40', '40-60', 'Acima de 80'];
  public averageSalaryActiveServerData: { data: number[], label: string }[] = [];
  public averageSalaryRetiredInactiveData: { data: number[], label: string }[] = [];
  public averageSalaryPensionersData: { data: number[], label: string }[] = [];
  public insuredMassData: { data: number[], label: string }[] = [];
  public distributionbyCarrerData: { data: number[], label: string }[] = [];
  public distributionbyAgeData: { data: number[], label: string }[] = [];
  public distributionbyAgePensionersData: { data: number[], label: string }[] = [];
  public distributionbySexData: { data: number[] }[] = [];
  public distributionbySexInativeData: { data: number[], label: string }[] = [];
  public distributionbySexPensionoersData: { data: number[], label: string }[] = [];
  public distributionbySexActiveData: { data: number[] }[] = [];
  public distributionbySexRetiredInactiveData: { data: number[] }[] = [];
  public distributionbySexPensionersData: { data: number[] }[] = [];

  constructor(private api: Api, private me: Me) {}

  async ngOnInit() {
    const dadosUsuario = this.me.currentUserValue;
    const json = JSON.parse(JSON.stringify(dadosUsuario));
    const cidade = json.user.cidade;
    if(!cidade) return;
    var listServer = await this.apiCall
   .get(`servidor/todos/cidade?id_cidade=${cidade}`).pipe(take(1))
    .toPromise();
    
    listServer = listServer.filter((s) => s.status != 5);

    // GRUPO GERAL
    // 1 - Distribuição da Massa Segurada
    this.totalActive =  listServer.filter((server) =>  server["status"] == 1).length;
    this.totalInative = listServer.filter((server) =>  server["status"] == 2).length;
    this.totalPensioners = listServer.filter((server) =>  server["status"] == 3).length;
    // 2 - Distribuição por sexo
    this.totalFemale = listServer.filter((f) => f["sexo"] == "F" && f["status"] == 1).length;
    this.totalMale = listServer.filter((m) => m["sexo"] == "M" && m["status"] == 1).length;
    this.totalFemaleGeneral = listServer.filter((f) => f["sexo"] == "F").length;
    this.totalMaleGeneral = listServer.filter((m) => m["sexo"] == "M").length;

    // GRUPO ATIVOS
    // 1 - Media Salarial por sexo
    this.averageMaleSalary = salaryDataMale(listServer);
    this.averageFemaleSalary = salaryDataFemale(listServer);
    this.averageGeneralSalary = salaryDataGeneral(this.averageFemaleSalary, this.averageMaleSalary);
    // 2 - Distribuição por carreira
      //ver aqui
    // 3 - Distribuição Idade Ativos
    this.totalFemale_0_20 = listServer.filter((f) => f["sexo"] == "F" && f["ativo"] == 1 &&
    calcAge(f["data_nascimento"]) <= 20).length;
    this.totalFemale_20_40 = listServer.filter((f) => f["sexo"] == "F" && f["ativo"] == 1 &&
    calcAge(f["data_nascimento"]) > 20 && calcAge(f["data_nascimento"]) <= 40).length;
    this.totalFemale_40_60 = listServer.filter((f) => f["sexo"] == "F" && f["ativo"] == 1 &&
    calcAge(f["data_nascimento"]) > 40 && calcAge(f["data_nascimento"]) <= 60).length;
    this.totalFemale_60_80 = listServer.filter((f) => f["sexo"] == "F" && f["ativo"] == 1 &&
    calcAge(f["data_nascimento"]) > 60).length;
    this.totalMale_0_20 = listServer.filter((f) => f["sexo"] == "M" && f["ativo"] == 1 &&
    calcAge(f["data_nascimento"]) <= 20).length;
    this.totalMale_20_40 = listServer.filter((f) => f["sexo"] == "M" && f["ativo"] == 1 &&
    calcAge(f["data_nascimento"]) > 20 && calcAge(f["data_nascimento"]) <= 40).length;
    this.totalMale_40_60 = listServer.filter((f) => f["sexo"] == "M" && f["ativo"] == 1 &&
    calcAge(f["data_nascimento"]) > 40 && calcAge(f["data_nascimento"]) <= 60).length;
    this.totalMale_60_80 = listServer.filter((f) => f["sexo"] == "M" && f["ativo"] == 1 &&
    calcAge(f["data_nascimento"]) > 60).length;
    // 4 - Média Sexo - Inativos
    this.totalFemaleInative = averageAgeByInactiveFemale(listServer);
    this.totalMaleInative = averageAgeByInactiveMale(listServer);
    // 5 - Distribuição Sexo - Ativos
    this.totalFemaleActive =  listServer.filter((f) =>  f["status"] == 1 && f["sexo"] == "F").length;
    this.totalMaleActive =  listServer.filter((m) =>  m["status"] == 1 && m["sexo"] == "M").length;

    // GRUPO APOSENTADOS
    // 1 - Distribuição por sexo - Inativos
    this.totalFemaleRetiredInactive =  listServer.filter((f) =>  f["status"] == 2 && f["sexo"] == "F").length;
    this.totalMaleRetiredInactive =  listServer.filter((m) =>  m["status"] == 2 && m["sexo"] == "M").length;
    // 2 - Valor Médio de Benefícios dos Servidores Inativos
    this.averageMaleRetiredInactiveSalary = salaryDataMaleRetiredInactive(listServer);
    this.averageFemaleRetiredInactiveSalary = salaryDataFemaleRetiredInactive(listServer);
    this.averageGeneralRetiredInactiveSalary = salaryDataGeneral(this.averageFemaleRetiredInactiveSalary, this.averageMaleRetiredInactiveSalary);

    // GRUPO PENSIONISTAS
    // 1 - Distribuição por sexo - pensionistas
    this.totalFemalePensioners =  listServer.filter((f) =>  f["status"] == 3 && f["sexo"] == "F").length;
    this.totalMalePensioners =  listServer.filter((m) =>  m["status"] == 3 && m["sexo"] == "M").length;
    // 2 - Idade média por sexo
    this.totalFemalePensionersAge = averageAgeByFemalePensioners(listServer);
    this.totalMalePensionersAge = averageAgeByMalePensioners(listServer);
    //3 - Distribuição por idade pensionistas
    this.totalFemalePensioners_20_40 = listServer.filter((f) => f["sexo"] == "F" && f["status"] == 3 &&
    calcAge(f["data_nascimento"]) > 20 && calcAge(f["data_nascimento"]) <= 40).length;
    this.totalFemalePensioners_40_60 = listServer.filter((f) => f["sexo"] == "F" && f["status"] == 3 &&
    calcAge(f["data_nascimento"]) > 40 && calcAge(f["data_nascimento"]) <= 60).length;
    this.totalFemalePensioners_60_80 = listServer.filter((f) => f["sexo"] == "F" && f["status"] == 3 &&
    calcAge(f["data_nascimento"]) > 60 && calcAge(f["data_nascimento"]) <= 80).length;
    this.totalMalePensioners_80 = listServer.filter((f) => f["sexo"] == "F" && f["status"] == 3 &&
    calcAge(f["data_nascimento"]) > 80).length;
    this.totalMalePensioners_20_40 = listServer.filter((f) => f["sexo"] == "M" && f["status"] == 3 &&
    calcAge(f["data_nascimento"]) > 20 && calcAge(f["data_nascimento"]) <= 40).length;
    this.totalMalePensioners_40_60 = listServer.filter((f) => f["sexo"] == "M" && f["status"] == 3 &&
    calcAge(f["data_nascimento"]) > 40 && calcAge(f["data_nascimento"]) <= 60).length;
    this.totalMalePensioners_60_80 = listServer.filter((f) => f["sexo"] == "M" && f["status"] == 3 &&
    calcAge(f["data_nascimento"]) > 60 && calcAge(f["data_nascimento"]) <= 80).length;
    this.totalFemalePensioners_80 = listServer.filter((f) => f["sexo"] == "M" && f["status"] == 3 &&
    calcAge(f["data_nascimento"]) > 80).length;
    //4 - Valor Médio de Benefícios dos Pensionistas
    this.averageMalePensionersSalary = salaryDataMalePensioners(listServer);
    this.averageFemalePensionersSalary = salaryDataFemalePensioners(listServer);
    this.averageGeneralPensionersSalary = salaryDataPensionersGeneral(this.averageFemalePensionersSalary,
      this.averageMalePensionersSalary);


    // Datasets
    this.distributionbySexActiveLabels = [ this.totalFemaleActive + ' Feminino', this.totalMaleActive +' Masculino'];
    this.distributionbySexActiveData = [
      { data: [this.totalFemaleActive, this.totalMaleActive, ]}
    ];
    this.distributionbySexInativeData = [
      { data: [this.totalFemaleInative], label: isNaN(this.totalFemaleInative) ? '0 Feminino' : this.totalFemaleInative + ' Feminino' },
      { data: [this.totalMaleInative], label: isNaN(this.totalMaleInative) ? '0 Masculino' : this.totalMaleInative + ' Masculino' },
    ];
    this.distributionbySexPensionoersData = [
      { data: [this.totalFemalePensionersAge], label: this.totalFemalePensionersAge + ' Feminino' },
      { data: [this.totalMalePensionersAge], label: this.totalMalePensionersAge +' Masculino' },
    ];

    const totalFemaleAge = this.totalFemale_0_20+this.totalFemale_20_40 + this.totalFemale_40_60+
    this.totalFemale_60_80;
    const totalMaleAge = this.totalFemale_0_20+this.totalMale_20_40 + this.totalMale_40_60+
    this.totalMale_60_80;
    this.distributionbyAgeData = [
      { data: [this.totalFemale_0_20, this.totalFemale_20_40, this.totalFemale_40_60,
        this.totalFemale_60_80], label:  totalFemaleAge + " Feminino"},
        { data: [this.totalMale_0_20, this.totalMale_20_40, this.totalMale_40_60,
          this.totalMale_60_80], label: totalMaleAge + " Masculino"},
    ];
    const totalFemaleAgePensioner = this.totalFemalePensioners_20_40+this.totalFemalePensioners_40_60 + this.totalFemalePensioners_80;
    const totalMaleAgePensioner = this.totalMalePensioners_20_40+this.totalMalePensioners_40_60 + this.totalMalePensioners_80;
    this.distributionbyAgePensionersData = [
      { data: [this.totalFemalePensioners_20_40, this.totalFemalePensioners_40_60,
        this.totalFemalePensioners_80], label: totalFemaleAgePensioner +  " Feminino"},
        { data: [this.totalMalePensioners_20_40, this.totalMalePensioners_40_60,
          this.totalMalePensioners_80], label: totalMaleAgePensioner + " Masculino"},
    ];
    this.distributionbySexLabels = [this.totalFemaleGeneral + ' Feminino', this.totalMaleGeneral + ' Masculino']
    this.distributionbySexData = [
      { data: [ this.totalFemaleGeneral, this.totalMaleGeneral]}
    ];

    this.insuredMassLabels = [this.totalActive + ' Ativos', this.totalInative +' Inativos',  this.totalPensioners + ' Pensionistas']
    this.insuredMassData = [
        { data: [this.totalActive, this.totalInative, this.totalPensioners], label: "insuredMass"}
      ];
    this.averageSalaryActiveServerData = [
      { data: [formatValue(this.averageFemaleSalary)], label: 'R$ ' + (isNaN(formatValue(this.averageFemaleSalary)) ? '0.0' : formatValue(this.averageFemaleSalary))  + ' Feminino' },
      { data: [formatValue(this.averageMaleSalary)], label: 'R$ ' + (isNaN(formatValue(this.averageMaleSalary))? '0.0' : formatValue(this.averageMaleSalary) ) + ' Masculino' },
      { data: [formatValue(this.averageGeneralSalary)], label:'R$ ' + (isNaN(formatValue(this.averageGeneralSalary)) ? '0.0' : formatValue(this.averageGeneralSalary)) + ' Geral' },
    ];
    this.distributionbySexRetiredInactiveLabels =  [this.totalFemaleRetiredInactive+' Feminino', this.totalMaleRetiredInactive+' Masculino'];
    this.distributionbySexRetiredInactiveData = [
      { data: [this.totalFemaleRetiredInactive, this.totalMaleRetiredInactive] }
    ];
    this.averageSalaryRetiredInactiveData = [
      { data: [formatValue(this.averageFemaleRetiredInactiveSalary)], label: 'R$ ' +  (isNaN(formatValue(this.averageFemaleRetiredInactiveSalary)) ? '0.0' : (isNaN(formatValue(this.averageFemaleRetiredInactiveSalary)))) +' Feminino' },
      { data: [formatValue(this.averageMaleRetiredInactiveSalary)],label: 'R$ ' +  (isNaN(formatValue(this.averageMaleRetiredInactiveSalary)) ? '0.0' : isNaN(formatValue(this.averageMaleRetiredInactiveSalary))) +' Masculino'  },
      { data: [formatValue(this.averageGeneralRetiredInactiveSalary)], label: 'R$ ' +  (isNaN(formatValue(this.averageGeneralRetiredInactiveSalary)) ? '0.0' : isNaN(formatValue(this.averageGeneralRetiredInactiveSalary))) +' Geral' },
    ];
    this.averageSalaryPensionersData = [
      { data: [formatValue(this.averageFemaleRetiredInactiveSalary)], label: 'R$ ' + (isNaN(formatValue(this.averageFemaleRetiredInactiveSalary)) ? '0' : formatValue(this.averageFemaleRetiredInactiveSalary)) + ' Feminino' },
      { data: [formatValue(this.averageMaleRetiredInactiveSalary)], label: 'R$ ' + (isNaN(formatValue(this.averageMaleRetiredInactiveSalary)) ? '0' : formatValue(this.averageMaleRetiredInactiveSalary)) + ' Masculino' },
      { data: [formatValue(this.averageGeneralRetiredInactiveSalary)], label: 'R$ ' + (isNaN(formatValue(this.averageGeneralRetiredInactiveSalary)) ? '0': formatValue(this.averageGeneralRetiredInactiveSalary)) + ' Geral' },
    ];
    this.distributionbySexPensionersLabels = [ this.totalFemalePensioners + ' Feminino', this.totalMalePensioners + ' Masculino'];
    this.distributionbySexPensionersData = [
      { data: [ this.totalFemalePensioners, this.totalMalePensioners]}
    ];
  }
}

 function salaryDataMale(list: any) : number {
  var totalSalaryMale = 0;
  var serverMale = list.filter((m) => m["sexo"] == "M" && m["ativo"] == 1 &&
   m["salario_base"] != 0 &&
   m["salario_base"] != null);

  //adicionando salarios aleatorios
  serverMale.forEach(male => {
    var salary = male["salario_base"]
    totalSalaryMale = totalSalaryMale + salary;
  });

  return (totalSalaryMale / serverMale.length);
}

function salaryDataMaleRetiredInactive(list: any) : number {
  var totalSalaryMale = 0;
  var serverMale = list.filter((m) => m["sexo"] == "M" && m["ativo"] == 2 &&
   m["valor_beneficio"] != 0 &&
   m["valor_beneficio"] != null);

  //adicionando salarios aleatorios
  serverMale.forEach(male => {
    var salary = male["valor_beneficio"]
    totalSalaryMale = totalSalaryMale + salary;
  });

  return (totalSalaryMale / serverMale.length);
}

function salaryDataMalePensioners(list: any) : number {
  var totalSalaryMale = 0;
  var serverMale = list.filter((m) => m["sexo"] == "M" && m["status"] == 3 &&
   m["valor_beneficio"] != 0 &&
   m["valor_beneficio"] != null);

  //adicionando salarios aleatorios
  serverMale.forEach(male => {
    var salary = male["valor_beneficio"]
    totalSalaryMale = totalSalaryMale + salary;
  });

  return (totalSalaryMale / serverMale.length);
}

function salaryDataFemale(list: any) : number {
  var totalSalaryFemale = 0;
  var serverFemale = list.filter((f) => f["sexo"] == "F" && f["status"] == 1 &&
  f["salario_base"] != 0 &&
  f["salario_base"] != null);
  serverFemale.forEach(femele => {
    var salary = femele["salario_base"];
    totalSalaryFemale = totalSalaryFemale + salary;
  });

  return (totalSalaryFemale / serverFemale.length);
}

function salaryDataFemaleRetiredInactive(list: any) : number {
  var totalSalaryFemale = 0;
  var serverFemale = list.filter((f) => f["sexo"] == "F" && f["status"] == 2 &&
  f["valor_beneficio"] != 0 &&
  f["valor_beneficio"] != null);
  serverFemale.forEach(femele => {
    var salary = femele["valor_beneficio"];
    totalSalaryFemale = totalSalaryFemale + salary;
  });

  return (totalSalaryFemale / serverFemale.length);
}

function salaryDataFemalePensioners(list: any) : number {
  var totalSalaryFemale = 0;
  var serverFemale = list.filter((f) => f["sexo"] == "F" && f["status"] == 3 &&
  f["valor_beneficio"] != 0 &&
  f["valor_beneficio"] != null);
  serverFemale.forEach(femele => {
    var salary = femele["valor_beneficio"];
    totalSalaryFemale = totalSalaryFemale + salary;
  });

  return (totalSalaryFemale / serverFemale.length);
}

 function averageAgeByInactiveFemale(list: any) : number {
  var totalAge = 0;
  var serverFemale = list.filter((f) =>  f["status"] == 2 && f["sexo"] == "F");
  serverFemale.forEach(female => {
    var age = calcAge(female["data_nascimento"]);
    totalAge = totalAge + age;
  });

  var averageAge = (totalAge / serverFemale.length);
  return Math.round(averageAge);
}

function averageAgeByFemalePensioners(list: any) : number {
  var totalAge = 0;
  var serverFemale = list.filter((f) =>  f["status"] == 3 && f["sexo"] == "F");
  serverFemale.forEach(female => {
    var age = calcAge(female["data_nascimento"]);
    totalAge = totalAge + age;
  });

  var averageAge = isNaN((totalAge / serverFemale.length)) ? 0 : (totalAge / serverFemale.length);
  return Math.round(averageAge);
}

function averageAgeByInactiveMale(list: any) : number {
  var totalAge = 0;
  var serverMale = list.filter((m) =>  m["status"] == 2 && m["sexo"] == "M");
  serverMale.forEach(male => {
    var age = calcAge(male["data_nascimento"]);
    totalAge = totalAge + age;
  });
  var averageAge = (totalAge / serverMale.length);
  return Math.round(averageAge);;
}

function averageAgeByMalePensioners(list: any) : number {
  var totalAge = 0;
  var serverMale = list.filter((m) =>  m["status"] == 3 && m["sexo"] == "M");
  serverMale.forEach(male => {
    var age = calcAge(male["data_nascimento"]);
    totalAge = totalAge + age;
  });
  var averageAge = (totalAge / serverMale.length);
  return Math.round(averageAge);;
}

function salaryDataGeneral(averageFemaleSalary: number, averageMaleSalary: number) : number {
  averageFemaleSalary = isNaN(averageFemaleSalary) ? 0.0 : averageFemaleSalary;
  averageMaleSalary = isNaN(averageMaleSalary) ? 0.0 : averageMaleSalary;;
  var totalSalary = averageFemaleSalary + averageMaleSalary;
  return (totalSalary / 2);
}

function salaryDataPensionersGeneral(averageFemaleSalary: number, averageMaleSalary: number) : number {
  var totalSalary = averageFemaleSalary + averageMaleSalary;
  return (totalSalary / 2);
}

function salaryDataGeneralRetiredInactive(averageFemaleSalary: number, averageMaleSalary: number) : number {
  var totalSalary = averageFemaleSalary + averageMaleSalary;
  return (totalSalary / 2);
}

// function distributionByCarrerMale(listTotal: any) : number {
//   var serverGeneral = listTotal.filter((m) => m["ativo"] == 1 && m["cargo"] == "");
// }

// function distributionByCarrerFemale(listTotal: any) : number {}

function calcAge(dataNascimento: string): number {
  const hoje: Date = new Date();
  const dataNasc: Date = new Date(dataNascimento);
  let idade: number = hoje.getFullYear() - dataNasc.getFullYear();
  const mes: number = hoje.getMonth() - dataNasc.getMonth();

  if (mes < 0 || (mes === 0 && hoje.getDate() < dataNasc.getDate())) {
    idade--;
  }

  return idade;
}

function formatValue(valor: number): number {
  console.log(valor)
  const valorArredondado: number = Math.round(valor * 100) / 100;
  return parseFloat(valorArredondado.toFixed(2));
}
